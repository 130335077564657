import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import * as S from './style';
import { Icon, Icons } from '../../styles/sales_page';
import Timer from '../Timer';

const SecondView = () => 
    <S.ProductsPanel>
        <S.Overview>
            <S.Headline>
                Extermine a Celulite com o Celluredux 
            </S.Headline>
            <S.Description>
                o Celluredux é um composto de vitaminas e minerais desenvolvido para mulheres que sofrem com a celulite. 
            </S.Description>
        </S.Overview>            
        <S.Warning> 
            <div className="text">Oferta Válida Somente Nesta Página e Por Tempo Limitado</div>
            <Timer initialHours={2} />
        </S.Warning>
        <S.Products id="products">
            <S.Product>
                <S.ProductHeadline>LEVE 1 POTE</S.ProductHeadline>
                <S.ProductSubHeadline>TRATAMENTO PARA 30 DIAS</S.ProductSubHeadline>
                <S.ProductImage>
                    <StaticImage  src="../../images/suplementos/celluredux.png" alt="Celluredux" />
                    <S.Discount><span>34%</span></S.Discount>
                </S.ProductImage>
                <S.Total>
                    <div>Valor Normal: <span id="de">R$189</span></div>
                    <div id="por">Nesta Oferta:</div>
                </S.Total>
                <S.Price>
                   <span>R$124</span> / pote
                </S.Price>
                <S.ButtonWrapper>
                    <S.Button href="https://go.metodoesbelta.com/pay/celluredux-upsell/1click">Comprar Agora</S.Button>
                </S.ButtonWrapper>
                <S.Bandeiras></S.Bandeiras>
                <S.Total>
                    De <span id="de">R$189</span> por <span id="por">R$124</span>
                </S.Total>
            </S.Product>
            <S.Product highlight>
                <S.Ribbon highlight>
                    <span>MAIS POPULAR</span>
                </S.Ribbon>
                <S.ProductHeadline>LEVE 2 POTES</S.ProductHeadline>
                <S.ProductSubHeadline>TRATAMENTO PARA 60 DIAS</S.ProductSubHeadline>
                <S.ProductImage>
                    <StaticImage  src="../../images/suplementos/celluredux2.png" alt="Celluredux Combo 2" />
                    <S.Discount><span>48%</span></S.Discount>
                    </S.ProductImage>
                <S.Total>
                    <div>Valor Normal: <span id="de">R$189</span></div>
                    <div id="por">Nesta Oferta: </div>
                </S.Total>
                <S.Price>
                   <span>R$98</span> / pote
                </S.Price>
                <S.ButtonWrapper>
                    <S.Button href="https://go.metodoesbelta.com/pay/celluredux-60-upsell/1click">Comprar Agora</S.Button>
                </S.ButtonWrapper>
                <S.Total>
                    Total: De <span id="de">R$299</span> por <span id="por">R$196</span>
                </S.Total>
                <S.Bandeiras></S.Bandeiras>
            </S.Product>
            <S.Product>
                <S.Ribbon>
                    <span>MELHOR NEGÓCIO</span>
                </S.Ribbon>
                <S.ProductHeadline>LEVE 3 POTES</S.ProductHeadline>
                <S.ProductSubHeadline>TRATAMENTO PARA 90 DIAS</S.ProductSubHeadline>
                <S.ProductImage>
                    <StaticImage  src="../../images/suplementos/celluredux3.png" alt="Celluredux Combo 3" />
                    <S.Discount><span>55%</span></S.Discount>
                    </S.ProductImage>
                <S.Total>
                    <div>Valor Normal: <span id="de">R$189</span></div>
                    <div id="por">Nesta Oferta:</div>
                </S.Total>
                <S.Price>
                   <span>R$85</span> / pote
                </S.Price>
                <S.ButtonWrapper>
                    <S.Button href="https://go.metodoesbelta.com/pay/celluredux-90-upsell/1click">Comprar Agora</S.Button>
                </S.ButtonWrapper>
                <S.Bandeiras></S.Bandeiras>
                <S.Total>
                    Total: De <span id="de">R$567</span> por <span id="por">R$255</span>
                </S.Total>
            </S.Product>
        </S.Products>
        <S.DenyDiscount href="https://metodoesbelta.portalemagrecer.com.br/thankyoucc">Não, Eu Não Quero Aproveitar Meu Desconto Exclusivo</S.DenyDiscount>
    </S.ProductsPanel>

export default SecondView;